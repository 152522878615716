import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, Typography, TextField, MenuItem, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { request } from '../_libs/api';

const BookNow = () => {
  const [open, setOpen] = useState(true); // Modal will open automatically when the page loads
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState('');
  const [seats, setPeople] = useState<number>(1);
  const [notes, setNotes] = useState('');
  const [name, setBookingName] = useState('');
  const [phone, setContactNumber] = useState('');
  const [email, setEmailAddress] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleClose = () => setOpen(false);

  const handleBooking = async (event: React.FormEvent) => {
    event.preventDefault();

    if (selectedDate && selectedTime && seats && name && phone) {
      const bookingDetails = {
        emailSubject: 'Table Booking Request',
        name,
        phone,
        email: email || 'Not Provided',
        date: selectedDate,
        time: selectedTime,
        seats,
        notes: notes || 'None',
      };

      const [hours, minutes] = selectedTime.split(':');
      const bookingDateTime = new Date(selectedDate);
      bookingDateTime.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
      const datetime = bookingDateTime.getTime();

      await request('/webform/booking', {
        datetime,
        selectedTime,
        seats,
        notes,
        name,
        phone,
        email,
        showConfirmation,
      })
        .then(() => {
          setSelectedDate('');
          setSelectedTime('');
          setPeople(0);
          setNotes('');
          setBookingName('');
          setContactNumber('');
          setEmailAddress('');
          setShowConfirmation(false);
        })
        .catch((error) => {
          console.error('BOOKING validation error', error, error.subError);
        });

      console.log('Booking Details:', JSON.stringify(bookingDetails, null, 2));
      setShowConfirmation(true);
      setTimeout(() => setShowConfirmation(false), 5000); // Hide confirmation after 5 seconds
      handleClose(); // Close the modal
    }
  };

  const generateTimeSlots = () => {
    const slots = [];
    for (let i = 12; i <= 22.5; i += 0.5) {
      const hours = Math.floor(i);
      const minutes = i % 1 === 0.5 ? '30' : '00';
      slots.push(`${hours}:${minutes}`);
    }
    return slots;
  };

  const disabledDates = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDay();
    const isTuesday = day === 2;
    const isHoliday = (date.getMonth() === 11 && date.getDate() === 25) || (date.getMonth() === 0 && date.getDate() === 1);
    return isTuesday || isHoliday;
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <Box>
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={{ bgcolor: '#fff', p: 4, mx: 'auto', mt: 5, width: '80%', maxWidth: '600px', borderRadius: 2 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>Booking Details</Typography>

            <TextField
              label="Booking Name"
              fullWidth
              value={name}
              onChange={(e) => setBookingName(e.target.value)}
              sx={{ mb: 2 }}
            />

            <TextField
              label="Contact Number"
              fullWidth
              value={phone}
              onChange={(e) => setContactNumber(e.target.value)}
              sx={{ mb: 2 }}
            />

            <TextField
              label="Email Address (Optional)"
              fullWidth
              value={email}
              onChange={(e) => setEmailAddress(e.target.value)}
              sx={{ mb: 2 }}
            />

            <TextField
              label="Select Date"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={selectedDate || ''}
              onChange={(e) => {
                const newDate = e.target.value;
                if (!disabledDates(newDate)) {
                  setSelectedDate(newDate);
                }
              }}
              sx={{ mb: 2 }}
            />

            {selectedDate && (
              <>
                <Typography variant="h6" sx={{ mt: 3 }}>Select a Time</Typography>
                <TextField
                  select
                  fullWidth
                  value={selectedTime}
                  onChange={(e) => setSelectedTime(e.target.value)}
                  sx={{ mt: 2 }}
                >
                  {generateTimeSlots().map((slot) => (
                    <MenuItem key={slot} value={slot}>
                      {slot}
                    </MenuItem>
                  ))}
                </TextField>

                <Typography variant="h6" sx={{ mt: 3 }}>Number of People</Typography>
                <TextField
                  type="number"
                  fullWidth
                  value={seats}
                  onChange={(e) => setPeople(parseInt(e.target.value, 10))}
                  inputProps={{ min: 1, max: 10 }}
                  sx={{ mt: 2 }}
                />

                <Typography variant="h6" sx={{ mt: 3 }}>Notes (Optional)</Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  sx={{ mt: 2 }}
                />

                <Grid container spacing={2} sx={{ mt: 3 }}>
                  <Grid item xs={6}>
                    <Button variant="contained" fullWidth onClick={handleBooking} sx={{ bgcolor: '#A52A2A', color: '#fff' }}>
                      Submit Booking
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button variant="outlined" fullWidth onClick={handleClose}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default BookNow;
