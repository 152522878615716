import React, { useState } from 'react';
import { Box, Button, Modal, Typography, TextField, MenuItem, Grid } from '@mui/material';
import { request } from '../_libs/api'

const BookingModal = () => {
  const [open, setOpen] = useState(false);
  // const [datetime, setDatetime] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [selectedTime, setSelectedTime] = useState('');
  const [seats, setPeople] = useState<number>(1);
  const [notes, setNotes] = useState('');
  const [name, setBookingName] = useState('');
  const [phone, setContactNumber] = useState('');
  const [email, setEmailAddress] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleBooking = async (event: React.FormEvent) => {
    event.preventDefault();

    if (selectedDate && selectedTime && seats && name && phone) {
      // Creating a JSON object with booking details
      const bookingDetails = {
        emailSubject: 'Table Booking Request',
        name,
        phone,
        email: email || 'Not Provided',
        date: selectedDate,
        time: selectedTime,
        seats,
        notes: notes || 'None',
      };


      const [hours, minutes] = selectedTime.split(':');
      const bookingDateTime = new Date(selectedDate);
      bookingDateTime.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);

      // Convert the Date object into a UNIX timestamp in milliseconds
      const datetime = bookingDateTime.getTime();

      await request('/webform/booking', {
        datetime,
        selectedTime,
        seats,
        notes,
        name,
        phone,
        email,
        showConfirmation
      })
      .then(() => {
        setSelectedDate('');
        setSelectedTime('');
        setPeople(0);
        setNotes('');
        setBookingName('');
        setContactNumber('');
        setEmailAddress('');
        setShowConfirmation(false);
      })
      .catch(error => {
        console.error('BOOKING validation error', error, error.subError)
      })

    // Reset the form after submission

      // Show confirmation message
      setShowConfirmation(true);

      // Reset fields after submission
      setSelectedDate(null);
      setSelectedTime('');
      setPeople(1);
      setNotes('');
      setBookingName('');
      setContactNumber('');
      setEmailAddress('');

      setTimeout(() => setShowConfirmation(false), 5000); // Hide confirmation after 5 seconds
      handleClose(); // Close the modal
    }
  };

  const generateTimeSlots = () => {
    const slots = [];
    for (let i = 12; i <= 22.5; i += 0.5) {
      const hours = Math.floor(i);
      const minutes = i % 1 === 0.5 ? '30' : '00';
      slots.push(`${hours}:${minutes}`);
    }
    return slots;
  };

  const getDisabledDateMessage = (dateString: string): string | null => {
    const date = new Date(dateString);
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set time to the start of the day to compare dates accurately
  
    if (date < today) {
      return 'The date selected is in the past';
    }
  
    const day = date.getDay();
    if (day === 2) {
      return 'We are closed';
    }
  
    const isHoliday =
      (date.getMonth() === 11 && date.getDate() === 25) || // Christmas
      (date.getMonth() === 0 && date.getDate() === 1); // New Year's Day
  
    if (isHoliday) {
      return 'No pre-booking allowed for special days';
    }
  
    return null; // If the date is not disabled, return null
  };

  return (
    <Box>
      <Button
  variant="contained"
  onClick={handleOpen}
  sx={{
    bgcolor: '#A52A2A',
    color: '#fff',
    padding: '16px 32px', // Increases the button's padding
    fontSize: '18px', // Increases the font size
    width: '100%', // Makes the button take up the full width of its container
  }}
>
  Book a Table
</Button>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={{ bgcolor: '#fff', p: 4, mx: 'auto', mt: 5, width: '80%', maxWidth: '600px', borderRadius: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>Booking Details</Typography>

          {/* Booking Name */}
          <TextField
            label="Booking Name"
            fullWidth
            value={name}
            onChange={(e) => setBookingName(e.target.value)}
            sx={{ mb: 2 }}
          />

          {/* Contact Number */}
          <TextField
            label="Contact Number"
            fullWidth
            value={phone}
            onChange={(e) => setContactNumber(e.target.value)}
            sx={{ mb: 2 }}
          />

          {/* Email Address */}
          <TextField
            label="Email Address (Optional)"
            fullWidth
            value={email}
            onChange={(e) => setEmailAddress(e.target.value)}
            sx={{ mb: 2 }}
          />

          {/* Date Picker */}
          <TextField
            label="Select Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={selectedDate || ''}
            onChange={(e) => {  
              const newDate = e.target.value;
              const disabledMessage = getDisabledDateMessage(newDate);
              if (disabledMessage) {
                alert(disabledMessage); // Display the specific message as an alert
              } else {
                setSelectedDate(newDate);
              }
            }}
            
            sx={{ mb: 2 }}
          />

          {selectedDate && (
            <>
              <Typography variant="h6" sx={{ mt: 3 }}>Select a Time</Typography>
              <TextField
                select
                fullWidth
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
                sx={{ mt: 2 }}
              >
                {generateTimeSlots().map((slot) => (
                  <MenuItem key={slot} value={slot}>
                    {slot}
                  </MenuItem>
                ))}
              </TextField>

              <Typography variant="h6" sx={{ mt: 3 }}>Number of People</Typography>
              <TextField
                type="number"
                fullWidth
                value={seats}
                onChange={(e) => setPeople(parseInt(e.target.value, 10))}
                inputProps={{ min: 1, max: 10 }}
                sx={{ mt: 2 }}
              />

              <Typography variant="h6" sx={{ mt: 3 }}>Notes (Optional)</Typography>
              <TextField
                fullWidth
                multiline
                rows={3}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                sx={{ mt: 2 }}
              />

              <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item xs={6}>
                  <Button variant="contained" fullWidth onClick={handleBooking} sx={{ bgcolor: '#A52A2A', color: '#fff' }}>
                    Submit Booking
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="outlined" fullWidth onClick={handleClose}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Modal>

      {/* Confirmation message */}
      {showConfirmation && (
        <Box     
          sx={{
            mt: 3,
            bgcolor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
            p: 2, // Padding to give some space inside the box
            borderRadius: 1, // Rounded corners for a cleaner look
            boxShadow: 3, // Slight shadow for better visibility
          }}
        >
          <Typography variant="h6" color="green">
            Booking request has been sent. 
            {email ? ' You will receive a confirmation via email shortly.' : ' You will be contacted via your phone number for confirmation.'}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default BookingModal;









